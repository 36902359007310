import {gql} from '@apollo/client';
import ProductVariationFragment from "./ProductVariation.fragment";

const selection = `
    id
    meditationCategory
    description
    descriptionTwo
    descriptionThree
    descriptionFour
    bulletPoints
    userPurchased
    type
    title`;

const ProductFragment = {
  fragment: gql`
      fragment Product on Product{
          ${selection}
          variations{ ...ProductVariation }
      }
      ${ProductVariationFragment.fragment}
  `

}
export default ProductFragment;
