import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import TopBar from '../../shared/components/TopBar'
import styled from 'styled-components'
import { Divider } from '../../shared/components/styled/Divider'
import { Container } from '@material-ui/core'
import Shop from "./pages/Shop";


const HomeContainer = styled(Container)({
  paddingTop: 18,
  paddingRight: 0,
  paddingLeft: 0
})

const HomeRouter = () => {
  let { path } = useRouteMatch()

  return (
    <HomeContainer>
      <TopBar/>
      <Divider style={{ marginTop: 8, marginBottom: 8 }}/>
      {/*<NavBar showMenu={true}/>*/}
      <Switch>
        <Route exact path={path}>
          <Shop/>
        </Route>
        {/*<Route exact path={}>
          <p>asdasdasdasdasd</p>
          <StripeCheckout/>
        </Route>*/}
      </Switch>
    </HomeContainer>
  )
}

export default HomeRouter
