import React, { useEffect } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

import { useDispatch } from "react-redux";
import { EmptyShoppingCartAction, RemoveQuickPurchase } from "../../../../redux/actions/shoppingCart.action";
import { FormButton } from "../../../../shared/components/styled/FormButton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 18
    }
  }),
);

const Title = styled.p`
  font-family: Roboto,serif;
  font-weight:300;
  font-size: 28px;
  text-align: center;
`;


const Subtitle = styled.p`
  font-family: 'roboto', serif;
  font-weight: 300;
  font-size: 18px;
  max-width: 400px;
  text-align: center;
`;

const Image =styled.img`
  max-width: 100%;
  object-fit: contain;
`

const Success = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const _handleGoToHome = () => {
    history.push(`/`);
  }

  useEffect(() => {
    dispatch(EmptyShoppingCartAction());
    dispatch(RemoveQuickPurchase());
  }, []);

  return (
    <Container className={classes.root}>
      <Grid container justify={"center"} direction={'column'} alignItems={'center'}>
        {/*<Image src={ThankYouImage}/>*/}

        <Title>THANK YOU SO MUCH</Title>
        <Subtitle>and welcome to our PRIXM LIFETIME group. Your support means that we can get all of these memory features done faster and better. It also means that your feedback will help make this app even better!
        </Subtitle>
        <Subtitle>An email will shortly arrive in your inbox with instructions about how to redeem your subscription!</Subtitle>
        <Title>THANK YOU we could not have done this without you!</Title>

        <FormButton primary={true} onClick={_handleGoToHome}>Go to Home</FormButton>
      </Grid>
    </Container>
  )
};

export default Success;
