import React, { useState } from 'react';
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import PrixmPhone from '../../../../../../assets/images/prixm-phone.png';
import StoryLineImageModal from "./StoryLineImageModal";


import MultipleImageModal from "./MultipleImageModal";
import HiResImageModal from "./HiResImageModal";


const Title = styled.p`
  text-align: center;
  font-size: 20px;
  font-family: 'roboto', serif;
`;

const List = styled.ul``;

interface IListItem {
  clicky: boolean;
}

const ListItem = styled.li`
  margin-top: 16px;
  cursor: ${(props: IListItem) => props.clicky ? 'pointer' : 'cursor'};
`;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      margin: "0 0 0 8px",
      fontFamily: 'roboto',
      fontWeight: 300,
      fontSize: 12,
      backgroundColor: "#FFC107",
      padding: 4,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        padding: 2,
        marginRight: 2,
        fontSize: 9,
      }
    },
    containerItemText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: "relative",
      left: -10,
    },
    listItemTitle: {
      fontFamily: 'roboto',
      fontSize: 16,
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 11,
      }
    },
    listItemLegend: {
      fontFamily: 'roboto',
      fontSize: 12,
      margin: "0 0 0 6px",
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 8,
      }
    },
    listItemContent: {
      fontFamily: 'roboto',
      fontWeight: 300,
      fontSize: 14,
      margin: 0,
      position: "relative",
      left: -10,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      }
    },
    image: {
      objectFit: 'contain',
      width: "50%",
      [theme.breakpoints.down('md')]: {
        width: 200
      }
    }
  }),
);

const LIST_ITEMS = [
  {
    key: 1,
    title: 'STORYLINE MOVIES',
    content: 'Create finished movies from photos and videos in your storyline-it\'s simple & fast!',
    badge: 'IN BETA'
  },
  {
    key: 2,
    title: 'MULTIPLE PHOTO POSTS',
    content: 'Organize your memories with photos, longer videos, and text throughout-in one post!',
    badge: null
  },
  {
    key: 3,
    title: 'HI-RES EXPORT',
    content: 'We want to make it easy for you to create albums & books! This export allows you to organize your memories and export them as you need.',
    badge: null
  },
  {
    key: 4,
    title: 'MUCH MUCH MORE!',
    content: 'With your support, we have many more features already in the works to help you enjoy your memories!',
    badge: 'COMING SOON!'
  }
]

const PrixmFeature = () => {
  const classes = useStyles();
  const [showStoryLine, setShowStoryline] = useState(false);
  const [showMultiple, setShowMultiple] = useState(false);
  const [showHiRes, setShowHiRes] = useState(false);
  const _handleOnClick = (item) => {
    switch (item.key) {
      case 1:
        setShowStoryline(true);
        break;
      case 2:
        setShowMultiple(true);
        break;
      case 3:
        setShowHiRes(true);
        break;

    }
  }

  return (
    <>
      <Grid container justify={'center'}>
        <Title>NEW PRIXM SUBSCRIPTION FEATURES</Title>
      </Grid>
      <Grid container direction={"row"} alignItems={'center'}>
        <Grid item xs={6} md={6} style={{ textAlign: 'center' }}>
          <img className={classes.image} src={PrixmPhone}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <List>
            {
              LIST_ITEMS.map((item) => (
                <ListItem key={String(item.key)} onClick={() => _handleOnClick(item)} clicky={item.key !== 4}>
                  <div className={classes.containerItemText}>
                    <p className={classes.listItemTitle}>{item.title}</p>
                    {
                      item.badge &&
                      <p className={classes.badge}>{item.badge}</p>
                    }
                    {
                      item.key !== 4 &&
                      <p className={classes.listItemLegend}>*click to see more details</p>
                    }
                  </div>

                  <p className={classes.listItemContent}>{item.content}</p>
                </ListItem>
              ))
            }
          </List>
        </Grid>
      </Grid>
      <StoryLineImageModal isVisible={showStoryLine} onClose={() => setShowStoryline(false)}/>
      <MultipleImageModal isVisible={showMultiple} onClose={() => setShowMultiple(false)}/>
      <HiResImageModal isVisible={showHiRes} onClose={() => setShowHiRes(false)}/>
    </>
  )
};


export default PrixmFeature;