export const ROOT_ROUTES = {
  HOME: 'home',
  CHECKOUT: 'checkout',
  REDEEM: 'redeem'
}

export const AUTH_ROUTES = {
  BASE: '/auth',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  VERIFY_EMAIL: '/verify-email',
  WEB_AUTH: '/web-auth'
}

export const HOME_ROUTES = {
  BASE: 'home',
  SHOP: 'shop',
}

export const REDEEM_ROUTES = {
  BASE: 'redeem',
  EARLY_ADOPTER: 'early-adopter',
  LIFETIME: 'lifetime',
  LIFETIME_PLUS: 'lifetime-plus'
}

export const CHECKOUT_ROUTES = {
  BASE: 'checkout',
  BUYER_IDENTIFICATION: 'buyer-identification',
  SUCCESS: 'success',
  ERROR: 'error'
}

export const SUBSCRIPTION_ROUTES = {
  SELECT_METHOD: 'select-method'
}
