import React from 'react';
import { Container } from "@material-ui/core";
import styled from "styled-components";
import { FormButton } from "../../../shared/components/styled/FormButton";
import { useHistory } from "react-router-dom";

const Title = styled.p`
  font-family: Roboto, serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
`;


const SuccessMessage = () => {
  const history = useHistory();
  const _handleGoToHome = () => {
    history.push(`/`);
  }

  return (
    <Container>
      <Title>Thank you!! We have your email and we will be emailing you as soon as your prixm subscription features are
        ready. Thank you! </Title>
      <FormButton primary={true} onClick={_handleGoToHome}>Go to Home</FormButton>
    </Container>
  )
};

export default SuccessMessage