import { gql } from "@apollo/client";


export const REDEEM_EARLY_ADOPTER = gql`
    mutation RedeemEarlyAdopter($input: RedeemEarlyAdopterInput!) {
        RedeemEarlyAdopter(input: $input) {
            message
        }
    }
`;

export default REDEEM_EARLY_ADOPTER;
