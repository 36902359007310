import styled from 'styled-components'
import {COLORS} from '../../../styles/colors'

interface IProps {
  primary?: boolean;
  width?: number
}

export const FormButton = styled.button`
  width: ${(props: IProps) =>
          props.width ? `${props.width}px` : '100%'};
  background-color: ${(props: IProps) =>
          props.primary ? '#000000' : '#FFFFFF'};
  color: ${(props: IProps) => (props.primary ? '#FFFFFF' : COLORS.APP_COLOR)};
  border: 1px solid ${COLORS.APP_COLOR};
  font-family: 'roboto-light',serif;
  border-radius: 3px;
  padding: 13px;

  :hover {
    cursor: pointer;
  }

  &:disabled {
    color: #bdbdbd;
    background: #eeeeee;
  }
`
