import React from 'react';
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
}))

interface IProps {
  formik: any;
}

const ShippingAddress = (props: IProps) => {
  const { formik } = props;
  const classes = useStyles();


  const formHasChanged = (value) => {
    return formik.touched[value] && formik.errors[value];
  }
  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              error={formHasChanged('street')}
              helperText={formHasChanged('street') ? formik.errors.street : null}
              value={formik.values.street}
              className={classes.textField}
              id={'street'}
              name={'street'}
              variant="outlined"
              label={'Address*'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={formHasChanged('city')}
              helperText={formHasChanged('city') ? formik.errors.city : null}
              value={formik.values.city}
              className={classes.textField}
              id={'city'}
              name={'city'}
              variant="outlined"
              label={'City*'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={formHasChanged('state')}
              helperText={formHasChanged('state') ? formik.errors.state : null}
              value={formik.values.state}
              className={classes.textField}
              id={'state'}
              name={'state'}
              variant="outlined"
              label={'State*'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={formHasChanged('zipCode')}
              helperText={formHasChanged('zipCode') ? formik.errors.zipCode : null}
              value={formik.values.zipCode}
              className={classes.textField}
              id={'zipCode'}
              name={'zipCode'}
              variant="outlined"
              label={'Zip Code*'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}/>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}


export default ShippingAddress;