import React, {useContext, useEffect, useState} from 'react';
import {CircularProgress, Container, createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import {loadStripe} from '@stripe/stripe-js';
import {useMutation} from "@apollo/client";
import {get} from 'lodash';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import { IProductState } from "../../../../shared/interfaces/redux/ProductState";
import { FormButton } from "../../../../shared/components/styled/FormButton";
import CREATE_STRIPE_CHECKOUT_SESSION from "../../../../graphql/stripeCheckout/createStripeCheckoutSession.mutation";
import Title from "../../../../shared/components/styled/Title";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 18
    }
  }),
);


const StripeCheckout = () => {
  const classes = useStyles();
  const history = useHistory();
  const productsReduxState = useSelector((state: any) => state.shoppingCart.products)
  const quickPurchaseProductState = useSelector((state: any) => state.shoppingCart.quickPurchaseProduct)

  const [checkoutSessionId, setCheckoutSessionId] = useState<string>(null);
  const [createCheckoutSession, {
    data: checkoutData,
    error: checkoutError,
    loading: checkoutLoading
  }] = useMutation(CREATE_STRIPE_CHECKOUT_SESSION);

  const loading = checkoutLoading;
  const error = checkoutError;


  const openCheckoutSession = async () => {
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: checkoutSessionId,
    });
  }

  const _handleGoToHome = () => {
    history.push(`/`);
  }

  useEffect(() => {
    if (productsReduxState && !checkoutSessionId) {
      const params: any = {};


      if (quickPurchaseProductState) {
        params.lineItems = [{
          quantity: quickPurchaseProductState.quantity,
          price: quickPurchaseProductState.product.price.id,
          variantId: quickPurchaseProductState.product.id,
          productId: quickPurchaseProductState.productId
        }];
      } else {
        params.lineItems = productsReduxState.map((product: IProductState) => {
          const quantity = product.quantity;
          const price = product.product.price.id;
          return {
            quantity,
            price,
            variantId: product.product.id,
            productId: product.productId
          }
        });
      }
      if (params.lineItems && params.lineItems.length > 0) {
        createCheckoutSession({
          variables: {
            input: params
          }
        }).then().catch((err) => {
          setCheckoutSessionId(null)
        });
      }

    }
  }, [productsReduxState, checkoutSessionId]);

  useEffect(() => {
    if (checkoutData) {
      //TODO:SAVE ON LOCALSTORAGE WITH REDUX
      setCheckoutSessionId(get(checkoutData, 'createStripeCheckoutSession.checkoutSession', null));

    }
  }, [checkoutData]);

  useEffect(() => {
    if (checkoutSessionId) {
      openCheckoutSession();
    }
  }, [checkoutSessionId]);


  return (
    <Container className={classes.root}>
      {
        loading &&
        <Grid container justify={"center"} direction={'column'} alignItems={'center'}>
          <Title>Redirecting to checkout</Title>
          <CircularProgress/>
        </Grid>
      }
      {
        error &&
        <Grid container justify={"center"} direction={'column'} alignItems={'center'}>
          <Title>We are sorry. We found a problem redirecting you to the checkout.</Title>
          <FormButton primary={true} onClick={_handleGoToHome}>Go to Home</FormButton>
        </Grid>
      }
    </Container>
  )
};

export default StripeCheckout;
