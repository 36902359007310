import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Container as MuiContainer,
  Button as MuiButton,
  Theme,
  withStyles,
} from "@material-ui/core";
import { amber } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import { QuickPurchaseCartAction } from "../../../../../redux/actions/shoppingCart.action";
import { IProduct } from "../../../../../shared/interfaces/Product";
import { useHistory } from "react-router-dom";
import { CHECKOUT_ROUTES } from "../../../../../shared/constants/routes";

const Container = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}))(MuiContainer);

const ImageContainer = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

const ProductPrice = styled.p`
  font-family: "roboto", serif;
  text-align: center;
  margin-top: 16px;
  color: #4e4e4e;
  font-size: 24px;
`;

const Button = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(amber[400]),
    backgroundColor: amber[400],
    maxWidth: "80%",
    paddingLeft: 50,
    paddingRight: 50,
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
}))(MuiButton);


interface IProps {
  product: IProduct;
}

const ShopItem = (props: IProps) => {
  const { product } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [quickPurchased, setQuickPurchased] = useState<boolean>(false);
  const successState = useSelector(
    (state: any) => state.shoppingCart.success
  );

  const _buy = () => {
    setQuickPurchased(true);

    dispatch(
      QuickPurchaseCartAction(
        1,
        product.variations[0].stripeProduct,
        product.id,
      )
    );
  };

  useEffect(() => {
    if (quickPurchased && successState) {
      history.push(`/${CHECKOUT_ROUTES.BASE}`)
      /*if (loggedUser) {
        history.push(`/${ROOT_ROUTES.CHECKOUT}`);
      } else {
        history.push(
          `${AUTH_ROUTES.BASE}${AUTH_ROUTES.LOGIN}/${TO_REDIRECT_FROM_LOGIN.CHECKOUT}`
        );
      }*/
    }
  }, [quickPurchased, successState]);

  const formatString = (unit_amount_decimal: string) => {
    const temp = unit_amount_decimal;
    return `$${temp.slice(0, temp.length - 2)}.${temp.slice(
      temp.length - 2
    )}`;
  };

  return (
    <Container>
      <ImageContainer
        alt={product.variations[0].title}
        src={product.variations[0].stripeProduct.images[0]}
      />
      <ProductPrice>
        {formatString(
          product.variations[0].stripeProduct.price
            .unit_amount_decimal
        )}
      </ProductPrice>
      <Button onClick={_buy}>{`Buy ${product.title}`}</Button>
    </Container>
  );
};

export default ShopItem;
