import {Button as MuiButton, Theme, withStyles} from "@material-ui/core";
import { amber } from "@material-ui/core/colors";


const Button = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(amber[400]),
    backgroundColor: amber[400],
    width:'100%',
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
}))(MuiButton);

export default Button;
