import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import HomeRouter from './Home'
import NoMatch from './NoMatch'
import CheckoutRouter from "./Checkout";
import { CHECKOUT_ROUTES, ROOT_ROUTES } from "../shared/constants/routes";
import RedeemRouter from "./Redeem";

const Root = () => {

  // const [getUser, { data: getUserData }] = useLazyQuery(GET_USER_PROFILE)
  // const { dispatch } = useContext(AuthContext)

  // const token = localStorage.getItem('token')
  /*
    useEffect(() => {
      if (token) {
        getUser()
      }
    }, [token, getUser])

    useEffect(() => {
      if (getUserData) {
        const temp = get(getUserData, 'UserProfile', null)
        if (temp) {
          dispatch({ type: ACTIONS.SET_USER, user: temp })
          dispatch({ type: ACTIONS.SET_TOKEN, token })
          if (temp.stripe && temp.stripe.status === 'PENDING') {
            //TODO: CHECK IF SUB CHANGED
          }
        }
      }
    }, [getUserData])*/

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomeRouter/>
        </Route>
        <Route path={`/${ROOT_ROUTES.CHECKOUT}`}>
          <CheckoutRouter/>
        </Route>
        <Route path={`/${ROOT_ROUTES.REDEEM}`}>
          <RedeemRouter/>
        </Route>
        <Route path="*">
          <NoMatch/>
        </Route>
      </Switch>


    </Router>
  )
}

export default Root
