import { gql } from "@apollo/client";


export const REDEEM_LIFETIME_PLUS = gql`
    mutation RedeemLifetimePlus($input: RedeemLifetimePlusInput!) {
        RedeemLifetimePlus(input: $input) {
            message
        }
    }
`;

export default REDEEM_LIFETIME_PLUS;
