import React from 'react';
import { Container, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import styled from "styled-components";
import ReactPlayer from "react-player";

import PrixmFeature from "./components/PrixmFeature";
import Products from "./components/Products";
import PrixmNotes from "./components/PrixmNotes";


const Title = styled.p`
  text-align: center;
  font-size: 20px;
  font-family: 'roboto', serif;
`;

const TermsContainer = styled.div`
  
  padding: 16px;
  background-color: #E0E0E0;
`;

const TermsTitle = styled.p`
  font-size: 14px;
  font-family: 'roboto', serif;
  font-weight: 100;
`;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: 0,
      paddingLeft: 0
    },
    marginTop: {
      marginTop: 18
    },
    containerText: {
      padding: 16
    }
  }),
);

const Shop = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ReactPlayer url='https://www.youtube.com/watch?v=35hP3I_7koI' width={"100%"} height={"100%"}/>
        </Grid>
        <PrixmNotes/>
      </Grid>
      <PrixmFeature/>
      <Products/>
      <Grid container>
        <Grid item xs={12}>
          <Title>A MESSAGE FROM PRIXM FOUNDERS:</Title>
        </Grid>
      </Grid>
      <Grid container  justify={"center"}>
        <ReactPlayer url='https://www.youtube.com/watch?v=wRWgeAM_hN4&ab_channel=PRIXM' width={"100%"} height={300}/>
      </Grid>
      <TermsContainer>
        <TermsTitle>*All services or products sold as “Lifetime” refer to the lifetime of the platform; not the lifetime
          of the subscriber. PRIXM LLC, will provide to “Lifetime” members access to the PRIXM Plus subscription
          features for as long as the PRIXM Plus subscription service, or something similar, is offered by PRIXM LLC.
          PRIXM LLC, in its sole discretion, reserves the right to decide to terminate individual features or the entire
          service and platform at any time and for any reason without liability. If the PRIXM Plus subscription service
          is permanently terminated for any reason before April 12, 2026, a prorated refund will be provided to
          “Lifetime” members.</TermsTitle>
      </TermsContainer>
    </Container>
  )
}

export default Shop
