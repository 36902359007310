import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from "@material-ui/core";
import ShopItem from "./ShopItem";
import { useQuery } from "@apollo/client";
import GET_PRODUCTS from "../../../../../graphql/product/getProducts.mutation";
import { IProduct } from "../../../../../shared/interfaces/Product";
import { get } from "lodash";
import styled from "styled-components";

const Title = styled.p`
  text-align: center;
  font-size: 20px;
  font-family: 'roboto', serif;
`;

const Products = () => {

  const { data, loading } = useQuery(GET_PRODUCTS, { fetchPolicy: 'cache-and-network' });
  const [shopItems, setShopItems] = useState<IProduct[]>([]);

  useEffect(() => {
    if (data) {
      setShopItems(get(data, 'getProducts', []));
    }
  }, [data]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Title>PRIXM LIFETIME MEMBERSHIP LEVELS:</Title>
        </Grid>
      </Grid>
      <Grid container justify={'center'}>
        {
          loading && <CircularProgress/>
        }
      </Grid>
      <Grid container direction={'row'} spacing={2} style={{ margin: 0 }} alignItems={"stretch"}>
        {shopItems.map((item) => (
          <Grid item md={4} xs={12} key={item.id}
                style={loading ? { pointerEvents: 'none' } : {}}>
            <ShopItem product={item}/>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Products;