import { IStripeProduct } from '../../shared/interfaces/StripeProduct'
import {
  SHOPPING_CART_ADD_PRODUCT, SHOPPING_CART_ADD_QUICK_PURCHASE, SHOPPING_CART_EMPTY,
  SHOPPING_CART_REMOVE_PRODUCT, SHOPPING_CART_REMOVE_QUICK_PURCHASE,
  SHOPPING_CART_UPDATE_QUANTITY,
} from '../types/shoppingCart.types'

export const addProductToShoppingCartAction = (
  quantity: number,
  product: IStripeProduct,
  productId: string) => {
  return (dispatch) => {
    dispatch({
      type: SHOPPING_CART_ADD_PRODUCT,
      payload: { product, quantity, productId },
    })
  }
}

export const removeProductFromShoppingCartAction = (
  productId: string,
) => {
  return (dispatch) => {
    dispatch({
      type: SHOPPING_CART_REMOVE_PRODUCT,
      payload: { productId },
    })
  }
}

export const EmptyShoppingCartAction = () => {
  return (dispatch) => {
    dispatch({
      type: SHOPPING_CART_EMPTY
    })
  }
}

export const QuickPurchaseCartAction = (
  quantity: number,
  product: IStripeProduct,
  productId: string
) => {
  return (dispatch) => {
    dispatch({
      type: SHOPPING_CART_ADD_QUICK_PURCHASE,
      payload: { product, quantity, productId },
    })
  }
}

export const RemoveQuickPurchase = () => {
  return (dispatch) => {
    dispatch({
      type: SHOPPING_CART_REMOVE_QUICK_PURCHASE
    })
  }
}

export const updateProductQuantityShoppingCartAction = (
  product: IStripeProduct,
  quantity: number,
  action: 'ADD' | 'REMOVE',
) => {
  return (dispatch) => {
    dispatch(({
      type: SHOPPING_CART_UPDATE_QUANTITY,
      payload: { product, quantity, action },
    }))
  }
}
