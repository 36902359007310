import React from "react";
import TopBar from "../../shared/components/TopBar";
import { Divider } from "../../shared/components/styled/Divider";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { REDEEM_ROUTES } from "../../shared/constants/routes";
import EarlyAdopter from "./pages/EarlyAdopter";
import Lifetime from "./pages/Lifetime";
import LifetimePlus from "./pages/LifetimePlus";

export const RedeemRouter = () => {
  let { path } = useRouteMatch()

  return(
    <>
      <TopBar/>
      <Divider style={{ marginTop: 8, marginBottom: 8 }}/>
      <Switch>
        <Route path={`${path}/${REDEEM_ROUTES.EARLY_ADOPTER}`}>
          <EarlyAdopter/>
        </Route>
        <Route path={`${path}/${REDEEM_ROUTES.LIFETIME}`}>
          <Lifetime/>
        </Route>
        <Route path={`${path}/${REDEEM_ROUTES.LIFETIME_PLUS}`}>
          <LifetimePlus/>
        </Route>
      </Switch>

    </>
  )
}

export default RedeemRouter;