import React, { useEffect, useState } from 'react';
import {
  Container as MuiContainer, FormControl, FormControlLabel, FormHelperText, FormLabel,
  Grid, LinearProgress, makeStyles,
  Paper, Radio as MuiRadio, RadioGroup,
  TextField,
  Theme,
  withStyles
} from "@material-ui/core";
import styled from "styled-components";
import Button from "../../../../shared/components/MuiButton";
import { amber, grey } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import REDEEM_EARLY_ADOPTER from "../../../../graphql/redemption/redeemEarlyAdopter.mutation";
import mainApiApolloClient from "../../../../config/MainApiApolloClient";
import { Alert } from "@material-ui/lab";
import { getApolloError } from "../../../../shared/utils/apolloError";
import SuccessMessage from "../../components/SuccessMessage";
import { get } from 'lodash';

const Container = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: grey[100],
    padding: 50
  },
}))(MuiContainer);

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    flexDirection: 'row'
  },
}))

const Title = styled.p`
  font-family: Roboto, serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: 'roboto', serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
`;


interface IForm {
  email: string;
  device: "ANDROID" | "IOS";
}


const EarlyAdopter = () => {
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [
          redeemEarlyAdopter,
          {
            data,
            loading,
            error: redeemError
          }
        ] = useMutation(REDEEM_EARLY_ADOPTER, { client: mainApiApolloClient });

  const error = redeemError;
  const onSubmit = (values: IForm) => {
    const input = {
      email: values.email,
      device: values.device
    }

    redeemEarlyAdopter({
      variables: {
        input
      }
    }).then().catch();
  }

  const validate = (values: IForm) => {
    const errors = {};
    if (!values.email) {
      errors['email'] = 'Required';
    }
    if (!values.device) {
      errors['device'] = 'Required';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: null,
      device: null
    },
    onSubmit,
    validate,
    validateOnMount: true
  });

  const formHasChanged = (value) => {
    return formik.touched[value] && formik.errors[value];
  }

  useEffect(() => {
    if (data) {
      const response = get(data, 'RedeemEarlyAdopter.message', null);
      if (response) {
        setShowSuccess(true);
      }

    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);


  if (showSuccess) {
    return (<SuccessMessage/>);
  }

  return (
    <Paper component={Container}>

      <Grid container direction={'column'} spacing={4}>
        {
          loading && <LinearProgress/>
        }
        <Grid item xs={12}>
          <Title>Thank you for buying the Early Adopter membership!</Title>
          <Subtitle>You are almost there. To redeem, enter the email you used to register your PRIXM account. If you
            have any issues redeeming, please email Brandon at brandon@prixm.com or support@prixm.com</Subtitle>
        </Grid>
        {
          error &&
          <Alert severity={'error'}>{getApolloError(error)}</Alert>
        }
        <Grid item xs={12}>
          <Grid container alignItems={'center'} spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                error={formHasChanged('email')}
                helperText={formHasChanged('email') ? formik.errors.email : null}
                value={formik.values.email}
                className={classes.textField}
                id={'email'}
                name={'email'}
                variant="outlined"
                label={'Email*'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset" error={formHasChanged('device')} className={classes.formControl}>
                <FormLabel component="legend">Device</FormLabel>
                <RadioGroup aria-label="device" name="device" id={'device'} value={formik.values.device}
                            onChange={formik.handleChange} row>
                  <FormControlLabel value="ANDROID" control={<MuiRadio color={"default"}/>} label="Android"/>
                  <FormControlLabel value="IOS" control={<MuiRadio color={"default"}/>} label="iOS"/>
                </RadioGroup>
                {
                  (formHasChanged('device') && formik.errors.device) &&
                  <FormHelperText>{formik.errors.device}</FormHelperText>
                }

              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={!formik.isValid} onClick={() => formik.handleSubmit()}>Redeem</Button>
        </Grid>
      </Grid>
    </Paper>
  )
}


export default EarlyAdopter;