import React, { useState } from 'react';
import { createStyles, Dialog, DialogContent, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import MultiplePhotosImage from '../../../../../../assets/images/multiple-photos.png';

const Image = styled.img`
  object-fit: contain;

  max-width: 100%;
`;

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
      borderColor: "#FFC107",
      borderWidth: 6,
      borderStyle: "solid"
    },
  }),
);

interface IContainerProps {
  showBorder: boolean;
}

const Container = styled.div`
  padding: 0;
  border-color: ${(props: IContainerProps) => props.showBorder ? "#FFC107" : "#FFFFFF"};
  border-width: 6px;
  border-style: solid;
`;

const MultipleImageModal = (props: IProps) => {
  const { isVisible, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog open={isVisible} onBackdropClick={onClose} keepMounted={true}>
      <DialogContent>
        <Container className={classes.container} showBorder={true}>
          <Grid container justify={'flex-end'} style={{ padding: 0 }}>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <Close style={{ color: "#FFC107", height: 48, width: 48 }}/>
              </IconButton>
            </Grid>
          </Grid>
          <Image src={MultiplePhotosImage}/>
        </Container>

      </DialogContent>

    </Dialog>
  )
};


export default MultipleImageModal