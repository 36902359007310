import React, { useEffect, useState } from 'react';
import {
  Container as MuiContainer, Divider,
  FormControl, FormControlLabel, FormHelperText, FormLabel,
  Grid, InputLabel, LinearProgress,
  makeStyles, MenuItem,
  Paper, Radio as MuiRadio, RadioGroup, Select,
  TextField,
  Theme, Typography,
  withStyles
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import styled from "styled-components";
import { useFormik } from "formik";
import Button from "../../../../shared/components/MuiButton";
import ShippingAddress from "../../components/ShippingAddress";
import SuccessMessage from "../../components/SuccessMessage";
import { Alert } from "@material-ui/lab";
import { getApolloError } from "../../../../shared/utils/apolloError";
import { useMutation } from "@apollo/client";
import mainApiApolloClient from "../../../../config/MainApiApolloClient";
import REDEEM_LIFETIME_PLUS from "../../../../graphql/redemption/redeemLifetimePlus.mutation";
import { get } from "lodash";

const Container = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: grey[100],
    padding: 50
  },
}))(MuiContainer);

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
}))

const Title = styled.p`
  font-family: Roboto, serif;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 28px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: 'roboto', serif;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
`;


interface IForm {
  primaryEmail: string;
  email1: string;
  email2: string;
  email3: string;
  email4: string;
  primaryTShirt: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  tshirt1: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  tshirt2: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  tshirt3: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  tshirt4: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  device: "ANDROID" | "IOS";
  state: string;
  city: string;
  street: string;
  zipCode: string;
}

const T_SHIRT_SIZES = ["XS", "S", "M", "L", "XL", "XXL"];


const LifetimePlus = () => {

  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [
          redeemLifetimePlus,
          { data, error, loading }
        ] = useMutation(REDEEM_LIFETIME_PLUS, { client: mainApiApolloClient });
  const onSubmit = (values: IForm) => {
    const input = {
      primaryEmail: values.primaryEmail,
      tshirts: [values.primaryTShirt, values.tshirt1, values.tshirt2, values.tshirt3, values.tshirt4],
      emails: [values.email1, values.email2, values.email3, values.email4],
      address: {
        state: values.state,
        city: values.city,
        street: values.street,
        zipCode: values.zipCode
      },
      device: values.device
    };
    redeemLifetimePlus({
      variables: {
        input
      }
    }).then().catch();
  }

  const validate = (values: IForm) => {
    const errors = {};
    if (!values.email1) {
      errors['email1'] = 'Required';
    }
    if (!values.email2) {
      errors['email2'] = 'Required';
    }
    if (!values.email3) {
      errors['email3'] = 'Required';
    }
    if (!values.email4) {
      errors['email4'] = 'Required';
    }
    if (!values.primaryEmail) {
      errors['primaryEmail'] = 'Required';
    }
    if (!values.tshirt1) {
      errors['tshirt1'] = 'Required';
    }
    if (!values.tshirt2) {
      errors['tshirt2'] = 'Required';
    }
    if (!values.tshirt3) {
      errors['tshirt3'] = 'Required';
    }
    if (!values.tshirt4) {
      errors['tshirt4'] = 'Required';
    }
    if (!values.primaryTShirt) {
      errors['primaryTShirt'] = 'Required';
    }
    if (!values.device) {
      errors['device'] = 'Required';
    }
    if (!values.state) {
      errors['state'] = 'Required';
    }
    if (!values.city) {
      errors['city'] = 'Required';
    }
    if (!values.street) {
      errors['street'] = 'Required';
    }
    if (!values.zipCode) {
      errors['zipCode'] = 'Required';
    }

    if (!values.device) {
      errors['device'] = 'Required';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email1: null,
      email2: null,
      email3: null,
      email4: null,
      primaryEmail: null,
      tshirt1: "S",
      tshirt2: "S",
      tshirt3: "S",
      tshirt4: "S",
      primaryTShirt: "S",
      device: null,
      state: null,
      city: null,
      street: null,
      zipCode: null,

    },
    onSubmit,
    validate,
    validateOnMount: true
  });

  const formHasChanged = (value) => {
    return formik.touched[value] && formik.errors[value];
  }

  useEffect(() => {
    if (data) {
      const response = get(data, 'RedeemLifetimePlus.message', null);
      if (response) {
        setShowSuccess(true);
      }
    }
  }, [data]);

  if (showSuccess) {
    return (<SuccessMessage/>);
  }


  const Item = (id, label) => (
    <Grid item xs={12}>
      <Grid container spacing={2} justify={"space-between"} direction={"row"}>
        <Grid item xs={8} md={6}>
          <TextField
            error={formHasChanged(`email${id}`)}
            helperText={formHasChanged(`email${id}`) ? formik.errors[`email${id}`] : null}
            value={formik.values[`email${id}`]}
            className={classes.textField}
            id={`email${id}`}
            name={`email${id}`}
            variant="outlined"
            label={label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}/>
        </Grid>
        <Grid item xs={4} md={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={formHasChanged(`tshirt${id}`)}
          >
            <InputLabel id={`select-tshirt${id}-label`}>T-Shirt Size</InputLabel>
            <Select
              labelId={`select-tshirt${id}-label`}
              id={`tshirt${id}`}
              name={`tshirt${id}`}
              value={formik.values[`tshirt${id}`]}
              onChange={formik.handleChange}
              label="T-Shirt"
            >
              {T_SHIRT_SIZES.map((size) => (
                <MenuItem key={size} value={size}>{size}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Paper component={Container}>

      <Grid container direction={'column'} spacing={4}>
        {
          loading && <LinearProgress/>
        }
        <Grid item xs={12} alignItems={'center'}>
          <Title>Thank you for buying the Lifetime Plus membership!</Title>
          <Subtitle>You are almost there. To redeem, enter the email you used to register your PRIXM account as well as
            the emails of 4 other people and everyone's t-shirt sizes. If you have any issues redeeming, please email
            Brandon at brandon@prixm.com or support@prixm.com</Subtitle>
        </Grid>
        {
          error &&
          <Alert severity={'error'}>{getApolloError(error)}</Alert>
        }
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography>Primary Email</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify={"space-between"} direction={"row"}>
            <Grid item xs={8} md={6}>
              <TextField
                error={formHasChanged('primaryEmail')}
                helperText={formHasChanged('primaryEmail') ? formik.errors.primaryEmail : null}
                value={formik.values.primaryEmail}
                className={classes.textField}
                id={'primaryEmail'}
                name={'primaryEmail'}
                variant="outlined"
                label={'Primary Email*'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}/>
            </Grid>
            <Grid item xs={4} md={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={formHasChanged('primaryTShirt')}
              >
                <InputLabel id={`select-primaryTShirt-label`}>T-Shirt Size</InputLabel>
                <Select
                  labelId={`select-primaryTShirt-label`}
                  id={`primaryTShirt`}
                  name={`primaryTShirt`}
                  value={formik.values.primaryTShirt}
                  onChange={formik.handleChange}
                  label="T-Shirt"
                >
                  {T_SHIRT_SIZES.map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography>Buddies</Typography>
        </Grid>
        {Item(1, "First buddy email")}
        {Item(2, "Second buddy email")}
        {Item(3, "Third buddy email")}
        {Item(4, "Fourth buddy email")}
        <Grid item xs={12} md={4}>
          <FormControl component="fieldset" error={formHasChanged('device')} className={classes.formControl}>
            <FormLabel component="legend">Are the majority of these users which type of user?</FormLabel>
            <RadioGroup aria-label="device" name="device" id={'device'} value={formik.values.device}
                        onChange={formik.handleChange} row>
              <FormControlLabel value="ANDROID" control={<MuiRadio color={"default"}/>} label="Android"/>
              <FormControlLabel value="IOS" control={<MuiRadio color={"default"}/>} label="iOS"/>
            </RadioGroup>
            {
              (formHasChanged('device') && formik.errors.device) &&
              <FormHelperText>{formik.errors.device}</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography>Shipping Address</Typography>
        </Grid>
        {<ShippingAddress formik={formik}/>}
        <Grid item alignItems={'center'} xs={12}>
          <Button onClick={() => formik.handleSubmit()} disabled={!formik.isValid}>Redeem</Button>
        </Grid>
      </Grid>
    </Paper>
  )
}


export default LifetimePlus;