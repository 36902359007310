import {gql} from "@apollo/client";



export const CREATE_STRIPE_CHECKOUT_SESSION = gql`
    mutation createStripeCheckoutSession($input: createStripeCheckoutSessionInput) {
        createStripeCheckoutSession(input: $input) {
            checkoutSession
        }
    }
`;

export default CREATE_STRIPE_CHECKOUT_SESSION;
