import React, { useEffect, useState } from 'react';
import {
  Container as MuiContainer, Divider,
  FormControl, FormControlLabel, FormHelperText, FormLabel,
  Grid, InputLabel, LinearProgress, makeStyles,
  MenuItem,
  Paper, Radio as MuiRadio, RadioGroup, Select,
  TextField,
  Theme, Typography,
  withStyles
} from "@material-ui/core";
import Button from "../../../../shared/components/MuiButton";
import { grey } from "@material-ui/core/colors";
import styled from "styled-components";
import { useFormik } from "formik";
import ShippingAddress from "../../components/ShippingAddress";
import SuccessMessage from "../../components/SuccessMessage";
import { useMutation } from "@apollo/client";
import REDEEM_LIFETIME from "../../../../graphql/redemption/redeemLifetime.mutation";
import mainApiApolloClient from "../../../../config/MainApiApolloClient";
import { Alert } from "@material-ui/lab";
import { getApolloError } from "../../../../shared/utils/apolloError";
import { get } from "lodash";

const Container = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: grey[100],
    padding: 50
  },
}))(MuiContainer);

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
}))

const Title = styled.p`
  font-family: Roboto, serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: 'roboto', serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
`;


interface IForm {
  email: string;
  tshirt1: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  tshirt2: "XS" | "S" | "M" | "L" | "XL" | "XXL";
  device: "ANDROID" | "IOS";
  state: string;
  city: string;
  street: string;
  zipCode: string;
}

const T_SHIRT_SIZES = ["XS", "S", "M", "L", "XL", "XXL"];

const Lifetime = () => {
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [
          redeemLifetime,
          { data, error, loading }
        ] = useMutation(REDEEM_LIFETIME, { client: mainApiApolloClient });

  const onSubmit = (values: IForm) => {
    const input = {
      email: values.email,
      tshirts: [values.tshirt1, values.tshirt2],
      address: {
        state: values.state,
        city: values.city,
        street: values.street,
        zipCode: values.zipCode
      },
      device: values.device
    };
    redeemLifetime({
      variables: {
        input
      }
    }).then().catch();
  }

  const validate = (values: IForm) => {
    const errors = {};
    if (!values.email) {
      errors['email'] = 'Required';
    }
    if (!values.tshirt1) {
      errors['tshirt1'] = 'Required';
    }
    if (!values.tshirt2) {
      errors['tshirt2'] = 'Required';
    }
    if (!values.device) {
      errors['device'] = 'Required';
    }
    if (!values.state) {
      errors['state'] = 'Required';
    }
    if (!values.city) {
      errors['city'] = 'Required';
    }
    if (!values.street) {
      errors['street'] = 'Required';
    }
    if (!values.zipCode) {
      errors['zipCode'] = 'Required';
    }

    if (!values.device) {
      errors['device'] = 'Required';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: null,
      tshirt1: "S",
      tshirt2: "S",
      device: null,
      state: null,
      city: null,
      street: null,
      zipCode: null,
    },
    onSubmit,
    validate,
    validateOnMount: true
  });

  const formHasChanged = (value) => {
    return formik.touched[value] && formik.errors[value];
  }

  useEffect(() => {
    if (data) {
      const response = get(data, 'RedeemLifetime.message', null);
      if (response) {
        setShowSuccess(true);
      }
    }
  }, [data]);

  if (showSuccess) {
    return (<SuccessMessage/>);
  }


  return (
    <Paper component={Container}>
      <Grid container direction={'column'} spacing={4}>
        {
          loading && <LinearProgress/>
        }
        <Grid item xs={12} alignItems={'center'}>
          <Title>Thank you for buying the Lifetime membership!</Title>
          <Subtitle>You are almost there. To redeem, enter the email you used to register your PRIXM account and two
            t-shirt sizes. If you have any issues redeeming, please email Brandon at brandon@prixm.com or
            support@prixm.com</Subtitle>
        </Grid>
        {
          error &&
          <Alert severity={'error'}>{getApolloError(error)}</Alert>
        }
        <Grid item xs={12}>
          <TextField
            error={formHasChanged('email')}
            helperText={formHasChanged('email') ? formik.errors.email : null}
            value={formik.values.email}
            className={classes.textField}
            id={'email'}
            name={'email'}
            variant="outlined"
            label={'Email*'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify={"space-between"} direction={"row"}>
            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={formHasChanged('tshirt1')}
              >
                <InputLabel id="select-tshirt1-label">T-Shirt Size</InputLabel>
                <Select
                  labelId="select-tshirt1-label"
                  id={"tshirt1"}
                  name={"tshirt1"}
                  value={formik.values.tshirt1}
                  onChange={formik.handleChange}
                  label="T-Shirt"
                >
                  {T_SHIRT_SIZES.map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={formHasChanged('tshirt2')}
              >
                <InputLabel id="select-tshirt2-label">T-Shirt Size</InputLabel>
                <Select
                  labelId="select-tshirt2-label"
                  id={"tshirt2"}
                  name={"tshirt2"}
                  value={formik.values.tshirt2}
                  onChange={formik.handleChange}
                  label="T-Shirt"
                >
                  {T_SHIRT_SIZES.map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset" error={formHasChanged('device')} className={classes.formControl}>
                <FormLabel component="legend">Device</FormLabel>
                <RadioGroup aria-label="device" name="device" id={'device'} value={formik.values.device}
                            onChange={formik.handleChange} row>
                  <FormControlLabel value="ANDROID" control={<MuiRadio color={"default"}/>} label="Android"/>
                  <FormControlLabel value="IOS" control={<MuiRadio color={"default"}/>} label="iOS"/>
                </RadioGroup>
                {
                  (formHasChanged('device') && formik.errors.device) &&
                  <FormHelperText>{formik.errors.device}</FormHelperText>
                }

              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography>Shipping Address</Typography>
        </Grid>
        {<ShippingAddress formik={formik}/>}
        <Grid item alignItems={'center'} xs={12}>
          <Button onClick={() => formik.handleSubmit()} disabled={!formik.isValid}>Redeem</Button>
        </Grid>
      </Grid>
    </Paper>
  )
}


export default Lifetime;