import { gql } from "@apollo/client";


export const REDEEM_LIFETIME = gql`
    mutation RedeemLifetime($input: RedeemLifetimeInput!) {
        RedeemLifetime(input: $input) {
            message
        }
    }
`;

export default REDEEM_LIFETIME;
