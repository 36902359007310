import {gql} from "@apollo/client";
import ProductFragment from "../fragments/Product.fragment";

export const GET_PRODUCTS = gql`
    query getProducts {
        getProducts {
             ...Product
        }
    }
    ${ProductFragment.fragment}
`;

export default GET_PRODUCTS;
