import React, { useEffect } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { FormButton } from "../../../../shared/components/styled/FormButton";
import { useHistory } from "react-router-dom";
import Title from "../../../../shared/components/styled/Title";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 18
    }
  }),
);


const Error = () => {
  const classes = useStyles();
  const history = useHistory();
  const _handleGoToHome = () => {
    history.push(`/`);
  }

  useEffect(() => {
    history.push(`/`);
  }, []);

  return (
    <Container className={classes.root}>
      <Container className={classes.root}>
        <Grid container justify={"center"} direction={'column'} alignItems={'center'}>
          <Title>There was an error processing your payment. Retry later</Title>
          <FormButton primary={true} onClick={_handleGoToHome}>Go to Home</FormButton>
        </Grid>
      </Container>
    </Container>
  )
};

export default Error;
