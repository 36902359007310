import React from 'react';
import { Grid } from "@material-ui/core";
import styled from "styled-components";


const TextContent = styled.p`
  text-align: center;
  font-size: 16px;
  font-family: 'roboto', serif;
  font-weight: 300;
`;

const Title = styled.p`
  text-align: center;
  font-size: 20px;
  font-family: 'roboto', serif;
`;

const PrixmNotes = () => {
  return (<Grid item xs={12} md={6}>
    <Grid container direction={'column'} alignItems={'stretch'}>
      <Grid item style={{padding:16}} xs={12}>
        <Title>ENJOY YOUR MEMORIES MORE WITH PRIXM LIFETIME</Title>
        <TextContent>Sign up now for your lifetime PRIXM subscription to access all of the features that we have
          created to allow you to share, organize and enjoy your photo and video memories.</TextContent>
      </Grid>
      <Grid item style={{ backgroundColor: "#F8D044",padding:16 }} xs={12}>
        <Title>WHAT YOUR LIFETIME SIGN-UP WILL DO FOR PRIXM</Title>
        <TextContent>
          This will allow us to support and maintain our current subscription features (see below) and
          roll out our future features more quickly.
        </TextContent>
        <TextContent>
          It also allows us to establish a dedicated community from which we receive feedback on member experience
          with the app.
        </TextContent>
        <TextContent>
          Finally, it rewards you– our early adopter– with a Lifetime Subscription to PRIXM.
        </TextContent>
      </Grid>
    </Grid>
  </Grid>)
}

export default PrixmNotes;