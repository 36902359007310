import {ApolloProvider} from '@apollo/client'
import React from 'react'
import './App.css'
import apolloClient from './config/apolloClient'
import Root from './pages/Root'
import {AuthProvider} from './shared/context/AuthContext'
import {Provider} from 'react-redux'
import store from './redux/store'

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <AuthProvider>
          <Root/>
        </AuthProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default App
