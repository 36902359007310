import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { CHECKOUT_ROUTES } from "../../shared/constants/routes";
import Success from "./pages/Success";
import Error from "./pages/Error";
import StripeCheckout from "./pages/StripeCheckout";
import TopBar from "../../shared/components/TopBar";
import { Divider } from "../../shared/components/styled/Divider";

const CheckoutRouter = () => {
  let { path } = useRouteMatch()

  return (
    <>
      <TopBar/>
      <Divider style={{ marginTop: 8, marginBottom: 8 }}/>
      <Switch>
        <Route exact path={path}>
          <StripeCheckout/>
        </Route>
        <Route path={`${path}/${CHECKOUT_ROUTES.SUCCESS}`}>
          <Success/>
        </Route>
        <Route path={`${path}/${CHECKOUT_ROUTES.ERROR}`}>
          <Error/>
        </Route>
      </Switch>
    </>

  )
}

export default CheckoutRouter
