import {gql} from '@apollo/client';

const selection = `
  id
  active
  created
  currency
  type
  unit_amount
  unit_amount_decimal`;

const StripePriceFragment = {
  fragment: gql`
      fragment StripePrice on StripePrice{
          ${selection}
      }
  `
}
export default StripePriceFragment;