import {gql} from '@apollo/client';
import StripeProductFragment from "./StripeProduct.fragment";

const selection = `
    title`;

const ProductVariationFragment = {
  fragment: gql`
      fragment ProductVariation on ProductVariation{
          ${selection}
          stripeProduct{ ...StripeProduct }
      }
      ${StripeProductFragment.fragment}
  `

}
export default ProductVariationFragment;
